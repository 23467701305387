import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: "Home",
    component: Home,
    children:[
      // {
      //   path: '/',
      //   name: '/',
      //   meta: { title: '预警总揽' },
      //   component: () => import( '../views/Index.vue')
      // },
      {
        path: '/',
        name: '/',
        meta: { title: '法人治理' },
        component: () => import( '../views/Gsfr.vue')
      },
      {
        path: '/progress',
        name: 'progress',
        component: () => import( '../views/progress.vue')
      },
      {
        path: '/test',
        name: 'test',
        meta: { title: '测试' },
        component: () => import( '../views/test.vue')
      },
      {
        path: '/xmgl',
        name: 'xmgl',
        meta: { title: '项目管理' },
        component: () => import( '../views/Xmgl.vue')
      },
      {
        path: '/yxgl',
        name: 'yxgl',
        meta: { title: '运营管理' },
        component: () => import( '../views/Yxgl.vue')
      },
      {
        path: '/yhgl',
        name: 'yhgl',
        meta: { title: '用户管理' },
        component: () => import( '../views/yhgl/Yhgl.vue')
      },
      {
        path: '/cdgl',
        name: 'cdgl',
        meta: { title: '菜单管理' },
        component: () => import( '../views/menu/Cdgl.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/Login.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
