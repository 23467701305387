import storage from '/src/store/index'
import {login} from "@/api/api";
import { ACCESS_TOKEN,REFRESH_TOKEN } from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    name: '',
    avatar: '',
    roles: [],
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESHTOKEN: (state, token) => {
      state.refreshtoken = token
    },
    SET_NAME: (state, { name }) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          // console.log('response:', response)
          // getSysUserVo({sysUserId: response.data.data.sysUserVo.sysUserId}).then(res=>{
          //   storage.set('orgName', res.data.data.orgName)
          //   localStorage.setItem('orgIdPath',res.data.data.orgIdPath)
          //   Vue.prototype.$uploadPublic = uploadPublic
          //
          // })
          // console.log(response.code)
          if (response.code == 200) {
            // console.log(storage)
            storage.set(ACCESS_TOKEN, response.result.accessToken, 7 * 24 * 60 * 60 * 1000)
            storage.set(REFRESH_TOKEN, response.result.accessToken, 7 * 24 * 60 * 60 * 1000)
            // storage.set('username', response.data.data.sysUserVo.account)
            // storage.set('sysRoleName', response.data.data.sysUserVo.sysRoleName)
            // storage.set('authorityUrlList', response.data.data.authorityUrlList)
            commit('SET_TOKEN', response.result.accessToken)
            commit('SET_REFRESHTOKEN', response.result.refreshtoken)
            // storage.set('roleId', '1')
            // 0 平台 1 代理商机构
            // localStorage.setItem('loginType', '0')
            // localStorage.setItem('sysUserId', response.data.data.sysUserVo.sysUserId)
            resolve()
          } else {
            reject(response.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.result
          if (result.role && result.role.permissions.length > 0) {
            const role = result.role
            role.permissions = result.role.permissions
            role.permissions.map(per => {
              if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                const action = per.actionEntitySet.map(action => {
                  return action.action
                })
                per.actionList = action
              }
            })
            role.permissionList = role.permissions.map(permission => {
              return permission.permissionId
            })
            commit('SET_ROLES', result.role)
            commit('SET_INFO', result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }

          commit('SET_NAME', { name: result.name})
          commit('SET_AVATAR', result.avatar)

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        let loginType = !localStorage.getItem('loginType') ? '1' : localStorage.getItem('loginType')
        // console.log(loginType)
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        storage.remove(ACCESS_TOKEN)
        localStorage.removeItem('loginType')
        if(loginType === '1' ) {
          router.replace({
            name: 'agentLogin'
          })
        }else {
          router.replace({
            name: 'login'
          })
        }

        resolve()
      })
    }

  }
}

export default user
