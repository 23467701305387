<template>
  <router-view />
</template>
<script>
  export default {
    provide() {
      return {
        // baseUrl: 'http://39.99.229.244:8005/', // 定义共享的数据
        baseUrl: 'https://xnhyl.hljzzjt.com/', // 定义共享的数据
        // baseUrl: 'http://192.168.1.124:5005/', // 定义共享的数据
      }
    },
  }
</script>
<style lang="less">
.ivu-tabs-bar {
    border-bottom:none !important;
  }
  .ivu-spin-fix{
    background: rgba(255,255,255,.1) !important;
  }
  .ivu-spin{
    color: #1876BB !important;
  }
.ivu-btn{
  margin-right: 5px;
}
  .ivu-btn-text{
    /*color: #ffffff !important;*/
  }
  .ivu-btn-text:hover{
    color: #2d81ff !important;
  }
  .iframe {
    z-index: 2001;
    position: absolute;
    width: 100%;
    height: 75vh;
    top: 0px;
    left: 0;

  }
  .iframeClose {
    z-index: 2001;
    position: absolute;
    top: 52px;
    right: 10px;
    width: 120px;
    height: 56px;
    /* background: #323639; */
    text-align: right;
    line-height: 56px;
    padding-right: 20px;
    cursor: pointer;
  }
  .ivu-date-picker-cells-cell-range:before{
    background: #82aad0 !important;
  }
  .ivu-date-picker,.ivu-input-wrapper{
    display: unset !important;
  }

  .ivu-col{
    display: flex;
    border-radius: 10px;
    .color{
      min-width: 45px;
      color: #010C19;
    }
  }
  .fj-list li {
    color: #1876BB;
    cursor: pointer;
    border-bottom: 1px #ddd dashed;
    line-height: 32px;
  }
  .fj-list .fj-del {
    cursor: pointer;
    float: right;
    margin-left: 20px;
    margin-top: 10px;
  }
  #app {
    font-family: '微软雅黑',"Microsoft YaHei" ,Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  body{
    background-color:transparent !important;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page-content {
    padding: 30px;
    line-height: 1.9;
    font-size: 16px;
    h1 {
      margin: 5px 0;
      font-size: 18px;
    }
    p {
      text-indent: 2em;
    }
    .img {
      text-align: center;
    }
    img {
      /*display: block;*/
      margin-top: 20px;
      max-width: 100%;
    }
  }
  .list-content {
    padding: 30px;
    height: 100%;
    .ivu-table-border {
      td {
        background-color: rgba(0, 0, 0, 0);
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .btn-min {
      font-size: 12px;
      background: #1876BB;
      border-radius: 4px 4px 4px 4px;
      border: unset;
    }
    .tabWrap {
      margin-top: 22px;
    }
    .title {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 0 20px 0;
      width: 170px;
      height: 56px;
      background: #1876BB;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 56px;
      text-align: center;
    }
    .searchWrap {
      display: flex;
    }
    /*.searchInput {*/
    /*  width: 160px !important;*/
    /*  background: rgba(255,255,255) !important;*/
    /*  border-radius: 4px 4px 4px 4px !important;*/
    /*  opacity: 1;*/
    /*  border: 1px solid #010C19 !important;*/
    /*  .ivu-select-selection {*/
    /*    background: unset !important;*/
    /*    border: none !important;*/
    /*    color: #fff !important;*/
    /*  }*/
    /*  .ivu-select-single .ivu-select-selection {*/
    /*    height: 30px !important;*/
    /*  }*/
    /*  .ivu-select-item {*/
    /*    color: #fff !important;*/
    /*  }*/
    /*  .ivu-select-item:hover {*/
    /*    background: #02696a !important;*/
    /*  }*/
    /*  .ivu-select-item-focus {*/
    /*    background: #02696a !important;*/
    /*  }*/
    /*  .ivu-select-dropdown {*/
    /*    background: rgba(255,255,255) !important;*/
    /*    border: 1px solid #010C19 !important;*/
    /*    color: #fff !important;*/
    /*  }*/
    /*  .ivu-input {*/
    /*    background: unset;*/
    /*    border: none;*/
    /*  }*/
    /*}*/

    .ivu-table {
      font-size: 16px !important;
      background: #fff;
    }

    /*border: 0px solid rgba(255,255,255,.2);*/
    .ivu-table-wrapper-with-border {
      border: 1px solid #C6DFF1;;
      border-bottom: 0;
      border-right: 0;
    }
    .ivu-table-border td,
    .ivu-table-border th {
      border-right:  1px solid #C6DFF1;;
    }
    .ivu-table:before {
      background: unset;
    }
    .ivu-table-border:after {
      background-color: rgba(255, 255, 255, 0.2);
    }
    .ivu-table-fixed-header th {
      background:#126169;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
     .ivu-table-header th {
      background: rgba(24, 118, 187, 0.1);
       color: #010C19;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .ivu-table .demo-table-info-row td {
      background-color: rgba(0, 0, 0, 0);
      color: #505050;
      border-bottom: 1px solid #C6DFF1;;
    }
  }
  .demo-upload-list{
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    margin-right: 4px;
  }
  .demo-upload-list img{
    width: 100%;
    height: 100%;
  }
  .demo-upload-list-cover{
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover{
    display: block;
  }
  .demo-upload-list-cover i{
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
  .ivu-upload-select{
    border: 1px solid ;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ivu-modal-content{
    background-color: #fff !important;
    box-shadow: inset 0px 0px 6px 1px #fff !important;
  }
  .ivu-modal-header{
    background:rgba(24, 118, 187, 1) ;
    color: #fff;
    border-bottom: 1px solid #fff !important;
  }
  .ivu-modal-footer{
    border-top: 1px solid  #fff !important;
  }
  .ivu-modal-header p, .ivu-modal-header-inner{
    color: #fff !important;
  }
  .ivu-form-inline .ivu-form-item{
    display: unset !important;
  }
  .ivu-col-span-12{
    display: block !important;
  }
  .ivu-col-span-24{
    display: block !important;
  }
  /*.ivu-input-wrapper i{*/
  /*  line-height: unset !important;*/
  /*}*/
  .ivu-date-picker .ivu-input-wrapper i{
    line-height: unset !important;
  }
  .ivu-form .ivu-form-item-label{
    color: #010C19 !important;
  }
  .ivu-select-selection {
    background: unset;
    border: none;
    color: #010C19;
  }
  .ivu-select-single .ivu-select-selection {
    height: 30px;
  }
  .ivu-select-item{
    color: #010C19 !important;
  }
  .ivu-select-input{
    color: #010C19 !important;
  }
  .ivu-input{
    color: #010C19 !important;
    background: rgba(255,255,255) !important;
    border-radius: 4px 4px 4px 4px !important;
    opacity: 1;
    display: unset;
    border: 1px solid #DCDEE2 !important;
  }
  .ivu-select-item:hover {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-cascader-menu-item:hover {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-select-item-focus {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-cascader-menu-item-focus {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-cascader-menu .ivu-cascader-menu-item-active{
    background: rgba(24, 118, 187, 0.2) !important;
    color: #fff !important;
  }
  .ivu-select-dropdown {
    background: #fff !important;
    /*border: 1px solid #010C19 !important;*/
    color: #010C19 !important;
  }
  .ivu-cascader-menu-item{
    color: #010C19 !important;
  }

  .ivu-select-selection {
    background: rgba(255,255,255) !important;
    border: 1px solid #DCDEE2 !important;
    color: #010C19 !important;
  }
  .ivu-select-single .ivu-select-selection {
    height: 32px !important;
  }
  .ivu-select-item {
    color: #010C19 !important;
  }
  .ivu-select-multiple .ivu-select-item-selected{
    background: unset !important;
  }
  .ivu-select-item:hover {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-cascader-menu-item:hover {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-cascader-menu-item-focus {
    background: rgba(24, 118, 187, 0.2) !important;
  }
  .ivu-cascader-menu .ivu-cascader-menu-item-active{
    background: rgba(24, 118, 187, 0.2) !important;
    color: #fff !important;
  }
  .ivu-cascader-menu-item{
    color: #010C19 !important;
  }
  .ivu-cascader-label{
    color: #010C19 !important;
  }
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
  background:none !important;
}
  //footer按钮
  .ivu-btn-primary{
    border: 1px solid #1876BB !important;
    background: #1876BB !important;
    color: #fff !important;
  }
  .m-lh32 {
    line-height: 32px;
  }

  .m-select {
    .ivu-select-selection {
      height: 32px !important;
    }
  }
.ivu-tabs-nav .ivu-tabs-tab{
  color: #010C19;
}
/*.list-content .ivu-table-fixed-header th{*/
/*  background:#C6DFF1 !important;*/
/*  color: #000;*/
/*  .list-content .ivu-table .demo-table-info-row td{*/
/*    background:#C6DFF1 !important;*/
/*  }*/
/*}*/

.kuai{
  width: 36px;
  height: 36px;
  border-radius: 0 10px 10px 0;
  background: #1876bb;
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
}
</style>

<!--<template>-->
<!--  <router-view />-->
<!--</template>-->

<!--<style lang="less">-->
<!--  #app {-->
<!--    font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--    -webkit-font-smoothing: antialiased;-->
<!--    -moz-osx-font-smoothing: grayscale;-->
<!--    color: #2c3e50;-->
<!--  }-->
<!--  body{-->
<!--    background-color:transparent !important;-->
<!--  }-->
<!--  ul {-->
<!--    margin: 0;-->
<!--    padding: 0;-->
<!--  }-->
<!--  li {-->
<!--    list-style: none;-->
<!--  }-->
<!--  .flex {-->
<!--    display: flex;-->
<!--    align-items: center;-->
<!--    justify-content: space-between;-->
<!--  }-->
<!--  .ivu-table{-->
<!--    font-size:16px !important;-->
<!--  }-->
<!--  .page-content {-->
<!--    padding: 30px;-->
<!--    line-height: 1.9;-->
<!--    font-size: 16px;-->
<!--    h1 {-->
<!--      margin: 5px 0;-->
<!--      font-size: 18px;-->
<!--    }-->
<!--    p {-->
<!--      text-indent: 2em;-->
<!--    }-->
<!--    .img {-->
<!--      text-align: center;-->
<!--    }-->
<!--    img {-->
<!--      margin-top: 20px;-->
<!--      max-width: 100%;-->
<!--    }-->
<!--  }-->
<!--  .list-content {-->
<!--    padding: 30px;-->
<!--    height: 100%;-->
<!--    .ivu-table-border {-->
<!--      td {-->
<!--        background-color: rgba(0, 0, 0, 0);-->
<!--        color: #fff;-->
<!--        border-bottom: 1px solid rgba(255, 255, 255, 0.2);-->
<!--      }-->
<!--    }-->
<!--    .btn-min {-->
<!--      font-size: 12px;-->
<!--      background: #1876BB;-->
<!--      border-radius: 4px 4px 4px 4px;-->
<!--      border: unset;-->
<!--    }-->
<!--    .tabWrap {-->
<!--      margin-top: 22px;-->
<!--    }-->
<!--    .title {-->
<!--      position: absolute;-->
<!--      top: 0;-->
<!--      left: 0;-->
<!--      border-radius: 0 0 20px 0;-->
<!--      width: 170px;-->
<!--      height: 56px;-->
<!--      background: #1876BB;-->
<!--      opacity: 1;-->
<!--      font-size: 18px;-->
<!--      font-family: Microsoft YaHei, Microsoft YaHei;-->
<!--      font-weight: bold;-->
<!--      color: #ffffff;-->
<!--      line-height: 56px;-->
<!--      text-align: center;-->
<!--    }-->
<!--    .searchWrap {-->
<!--      display: flex;-->
<!--    }-->
<!--    .searchInput {-->
<!--      width: 160px;-->
<!--      background: rgba(255,255,255);-->
<!--      border-radius: 4px 4px 4px 4px;-->
<!--      opacity: 1;-->
<!--      color:#fff;-->
<!--      border: 1px solid #010C19;-->

<!--      .ivu-select-selection {-->
<!--        background: unset;-->
<!--        border: none;-->
<!--        color: #fff;-->
<!--      }-->
<!--      .ivu-select-single .ivu-select-selection {-->
<!--        height: 30px;-->
<!--      }-->
<!--      .ivu-select-item {-->
<!--        color: #fff;-->
<!--      }-->
<!--      .ivu-select-item:hover {-->
<!--        background: #02696a !important;-->
<!--      }-->
<!--      .ivu-select-item-focus {-->
<!--        background: #02696a !important;-->
<!--      }-->
<!--      .ivu-select-dropdown {-->
<!--        background: rgba(255,255,255);-->
<!--        border: 1px solid #010C19;-->
<!--        color: #fff;-->
<!--      }-->
<!--      .ivu-input {-->
<!--        background: unset;-->
<!--        border: none;-->
<!--        color: #fff;-->
<!--      }-->
<!--    }-->

<!--    .ivu-table {-->
<!--      background: #0e2b35;-->
<!--    }-->

<!--    /*border: 0px solid rgba(255,255,255,.2);*/-->
<!--    .ivu-table-wrapper-with-border {-->
<!--      border: 1px solid rgba(255, 255, 255, 0.2);-->
<!--      border-bottom: 0;-->
<!--      border-right: 0;-->
<!--    }-->
<!--    .ivu-table-border td,-->
<!--    .ivu-table-border th {-->
<!--      border-right: 1px solid rgba(255, 255, 255, 0.2);-->
<!--    }-->
<!--    .ivu-table:before {-->
<!--      background: unset;-->
<!--    }-->
<!--    .ivu-table-border:after {-->
<!--      background-color: rgba(255, 255, 255, 0.2);-->
<!--    }-->
<!--    .ivu-table-header th {-->
<!--      background: rgba(24, 118, 187, 0.1);-->
<!--      color: #fff;-->
<!--      font-size: 14px;-->
<!--      font-weight: bold;-->
<!--      border-bottom: 1px solid rgba(255, 255, 255, 0.2);-->
<!--    }-->
<!--    .ivu-table .demo-table-info-row td {-->
<!--      background-color: rgba(0, 0, 0, 0);-->
<!--      color: #fff;-->
<!--      border-bottom: 1px solid rgba(255, 255, 255, 0.2);-->
<!--    }-->
<!--  }-->
<!--  //footer按钮-->
<!--  .ivu-btn-primary{-->
<!--    border: 1px solid #0e2b35 !important;-->
<!--    background: #1876BB !important;-->
<!--    color: #fff !important;-->
<!--  }-->
<!--  .ivu-modal-content{-->
<!--    background-color: #0e2b35 !important;-->
<!--    box-shadow: inset 0px 0px 6px 1px #1876BB !important;-->
<!--  }-->
<!--  .ivu-modal-header{-->
<!--    border-bottom: 1px solid rgba(255,255,255,0.2) !important;-->
<!--  }-->
<!--  .ivu-modal-footer{-->
<!--    border-top: 1px solid rgba(255,255,255,0.2) !important;-->
<!--  }-->
<!--  .ivu-modal-header p, .ivu-modal-header-inner{-->
<!--    color: #1876BB !important;-->
<!--  }-->
<!--</style>-->
