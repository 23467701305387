import { createApp } from 'vue'
import App from '../App'
const app = createApp(App);

import Vuex from 'vuex'
import user from './modules/user'
// default router permission control

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

app.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
})
