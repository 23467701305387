import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import Vuex from 'vuex'
import { nanoid } from "nanoid";
import store  from './store'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import 'vue3-video-play/dist/style.css' // 引入css
import vue3videoPlay from 'vue3-video-play' // 引入组件
// import 'vue3-video-play/dist/style.css' // 引入css
import Axios from "axios";
import mitt from "mitt"
import * as echarts from 'echarts';
const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
// app.config.globalProperties.$baseUploadUrl="http://39.99.229.244:8005/api/"
app.config.globalProperties.$baseUploadUrl="https://xnhyl.hljzzjt.com/api/"
// app.config.globalProperties.$baseUploadUrl="http://192.168.1.124:8005/api/"
// app.config.globalProperties.$baseUrl="http://39.99.229.244:8005/"
app.config.globalProperties.$baseUrl="https://xnhyl.hljzzjt.com/"
app.config.globalProperties.$qz=localStorage.getItem('qzcode')
// app.config.globalProperties.$baseUrl="http://192.168.1.124:5005/"

app.use(router).use(Vuex).use(store).use(ViewUIPlus).use(vue3videoPlay).mount('#app')

app.config.globalProperties.$http = Axios;
app.config.globalProperties.$bus = new mitt();
