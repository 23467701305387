<template>
  <div class="body" :class="{ body2: (wintype == 'ue' || wintype == 'me') }">
    <div class="topImg" v-if="wintype != 'ue' && wintype != 'me'">
      <img class="top-left" src="../assets/bg-top-l.png" alt="" />
<!--      <div class="imgLr"></div>-->
      <div class="top-center">
        <img class="logo" src="../assets/logo.png" alt="" srcset="" />
        <img style="height: 88px;width: 548px" src="../assets/bg-top-m.png" alt="" srcset="" />
      </div>
<!--      <div class="imgLr"></div>-->
      <img class="top-right" src="../assets/bg-top-r.png" alt="" />
      <div class="top-r-icon" style="display: flex">
        <div style="position: relative" v-if="userInfo.roleName != '工区单位' && userInfo.roleName != '监理单位'&& userInfo.roleName != '监理单位' && userInfo.orgName != '项目公司工程管理部' ">
          <img @click="showMsg = !showMsg" class="icons" src="../assets/msg.png" alt=""  />
          <div class="num" v-if="infoList.length!= 0">{{total}}</div>
        </div>
          <Dropdown trigger="hover" style="margin-left: 20px">
              <a href="javascript:void(0)">
<!--                  <Icon type="ios-arrow-down"></Icon>-->

                  <img src="../assets/out.png" class="icons" alt=""  />
              </a>
              <template #list>
                  <DropdownMenu>
                      <DropdownItem @click="changePs">修改密码</DropdownItem>
                      <DropdownItem @click="loginOut">退出登录</DropdownItem>
                  </DropdownMenu>
              </template>
          </Dropdown>
      </div>
      <transition name="move-up">
        <div v-show="showMsg" class="msgWrap transition-box">
          <Affix>
            <div class="msg-top">
              <div class="msg-title">最新消息</div>
              <div class="msg-close" @click="showMsg = false">
                <Icon size="16" type="md-close"></Icon>
              </div>
            </div>
          </Affix>
          <div class="msg-cont">
            <div v-for="(item, index) in infoList" :key="index" class="msgList" @click="getUrl(item.id)">
              <div class="msgListIcon"><img src="../assets/ic-yujing1.png" alt="" /></div>
              <div>
                <div class="msgListTitle">{{ item.alarmBody }}</div>
                <div class="msgListCont">{{ item.location }}</div>
              </div>
            </div>
          </div>
          <Page :total="total" show-total :page-size="pageSize" :transfer="true"	@on-change="changePage" size="small" style="float: right" />
          <!-- <Button class="btn" type="primary"  icon="md-menu">查看全部</Button> -->
        </div>
      </transition>
    </div>
    <div class="layout" :class="{ layout2: wintype == 'ue', layout3: wintype == 'me' }">
      <div class="navBox" v-if="wintype != 'ue' && wintype != 'me'">
        <div class="headers">
          当前位置：
          <Breadcrumb separator=">">
<!--            <BreadcrumbItem>数字孪生管理平台</BreadcrumbItem>-->
            <BreadcrumbItem>{{ title }}</BreadcrumbItem>
            <BreadcrumbItem v-if="title2">{{ title2 }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="nav" >
          <ul>
<!--            <li @click="linkto('/')" :class="[index == '/' ? 'active' : '']">预警总览</li>-->
            <li v-if="findContent('公司法人治理')"  @click="linkto('/')" :class="[index == '/' ? 'active' : '']">
              法人治理
            </li>
            <li v-if="findContent('项目管理')" @click="linkto('/xmgl')" :class="[index == 'xmgl' ? 'active' : '']">
              项目管理
            </li>
            <li v-if="findContent('运营管理')" @click="linkto('/yxgl')" :class="[index == 'yxgl' ? 'active' : '']">
              运营管理
            </li>
            <li v-if="showUser" @click="linkto('/yhgl')" :class="[index == 'yhgl' ? 'active' : '']">
              用户管理
            </li>
            <!--            <li-->
            <!--                    @click="linkto('cdgl')"-->
            <!--                    :class="[index == 'cdgl' ? 'active' : '']"-->
            <!--            >-->
            <!--              菜单管理-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
      <router-view />
    </div>
      <Modal
              v-model="showPs"
              title="修改密码"
              :loading="loading"
              @on-ok="handleSubmit('formValidate')"
              width="30%"
      >
          <div class="password">
              <Form ref="formValidate" inline :model="formValidate" :rules="ruleValidate" label-position="left" :label-width="80">
                  <Row :gutter="16">
                      <Col span="24">
                          <FormItem label="旧密码" prop="passwordOld" >
                              <Input v-model="formValidate.passwordOld" type="password" password placeholder="请输入旧密码"></Input>
                          </FormItem>
                      </Col>
                      <Col span="24">
                          <FormItem label="新密码" prop="passwordNew">
                              <Input v-model="formValidate.passwordNew" type="password" password placeholder="请输入新密码"></Input>
                          </FormItem>
                      </Col>
                  </Row>
              </Form>
          </div>
      </Modal>
  </div>
</template>
<script>
  import {getUserInfo,alarmList,alarmUrl,ChangePwd,sysOrgListById} from '@/api/api'
export default {
  data() {
    return {
        userInfo:{},
      menu:[],
      showRole: false,
      showUser: false,
      showMsg: false,
      isCollapsed: false,
      index: "/",
      title: "",
      title2: "",
      wintype: localStorage.getItem("wintype"),
      infoList:[],
      page:1,
      pageSize:20,
      total:0,
        loading: true,
        passwordOld:'',
        passwordNew:'',
        showPs: false,
        formValidate:{
            passwordOld:'',
            passwordNew:''
        },
        ruleValidate: {
            passwordOld: [
                { required: true, message: '请输入旧密码', trigger: 'blur' }
            ],
            passwordNew: [
                { required: true, message: '请输入新密码', trigger: 'blur' }
            ],
        },
    };
  },
  mounted() {
    // // console.log(this.$route);
    var _this = this;
     this.menu = JSON.parse(localStorage.getItem('menu'))
    this.getCode()
    this.$nextTick(function () {
      // console.log(123);
      _this.$bus.on("bus", (data) => {
        _this.title2 = data;
        // this.$bus.emit('bus', this.$route.name);
        // console.log(data, _this.title2);
      });
    });
    this.title = this.$route.meta.title;
    if (this.$route.name != this.index) {
      this.index = this.$route.name;
    }
    if (this.$route.query.wintype) {
      this.wintype = this.$route.query.wintype;
      localStorage.setItem("wintype", this.$route.query.wintype);
    }
    this.getUser()
    this.getWarning()
    setInterval(()=>{
      this.getWarning()
    },3000000)
  },
  computed: {
    rotateIcon() {
      return ["menu-icon", this.isCollapsed ? "rotate-icon" : ""];
    },
    menuitemClasses() {
      return ["menu-item", this.isCollapsed ? "collapsed-menu" : ""];
    },
  },
  methods: {
      getCode(){
          sysOrgListById({id:'15914317109701'}).then(res=>{
              const map = {
                  label: "name",
                  children: "children",
                  value: "name"
              };
              this.bdList = this.convertTree(res.result, map);
              localStorage.setItem('bdList',JSON.stringify(this.bdList))
          })
      },
      convertTree(tree, map) {
          const result = [];
          tree.forEach(item => {
              // 读取 map 的键值映射
              item[map.children] = item[map.children]
                  ? item[map.children]
                  : [];
              let expand = false;
              let label = item[map.label];
              let value = item[map.value];
              let children = item[map.children] ? item[map.children] : null;
              // 如果有子节点，递归
              if (children) {
                  children = this.convertTree(children, map);
              }
              result.push({ expand, label, value, children });
          });
          return result;
      },
      handleSubmit (name) {
          this.loading = true
          this.$refs[name].validate((valid) => {
              if (valid) {
                  this.$Modal.confirm({
                      title: '提示',
                      content: '是否确认修改?',
                      onOk: () => {
                          ChangePwd(this.formValidate).then(res=>{
                              if (res.code == 200){
                                  this.$Message.success('密码成功');
                                  this.showPs = false
                                  this.loading = false
                                  this.formValidate = {
                                      passwordOld:'',
                                      passwordNew:''
                                  },
                                      this.loginOut()
                              }else{
                                  this.loading = false
                                  this.showPs = true
                                  this.$Message.info(res.message);

                              }
                          })
                      },
                  });
              } else {
                  this.$Message.error('请输入必填项!');
                  this.loading = false
                  setTimeout(()=>{
                      this.loading = true
                  },0)
              }
          })
      },

      changePs(id){
          this.userId = id
          this.showPs = true
      },
    getUrl(id){
      alarmUrl({Id:id}).then(res=>{
        // console.log(res)
      })
    },
    postData() {
      var query_name = {
        Field: "name",
        Value: this.searchName,
        op: "Contains",
      };

      //项目名称
      var query_type = {
        Field: "Type",
        Value:'LXJD',
        op: "Equals",
      };



      var postData = {
        page: this.page,
        pageSize: this.pageSize,
        "sortField": "AlarmTime",
        "sortOrder": "DESC",
        wheres: JSON.stringify([
          // query_name,
          // query_type
        ]),
      };
      return postData;
    },
    changePage(val){
      this.page = val
      this.getWarning()
    },
    getWarning(){
      alarmList(this.postData()).then(res=>{
        this.infoList = res.result.items
         this.total = res.result.total;  this.page = res.result.page
      })
    },
    findContent(field) {
      var data = JSON.parse(localStorage.getItem('menu')) ? JSON.parse(localStorage.getItem('menu')) : []
      data.some(item=> item.title === field)
      var status = data.some((item) => item.title == field);
      return status;
  },
    // findContent(name) {
    //   var arr = JSON.parse(localStorage.getItem('menu'))
    //   // 判断当前元素是否包含目标内容
    //   if (arr.includes(name)) {
    //     return true;
    //   } else {
    //     for (let i = 0; i < arr.length; i++) {
    //       // 如果当前元素是数组类型，则进行递归调用
    //       if (Array.isArray(arr[i])) {
    //         const result = this.findContent(arr[i]);
    //         if (result === true) {
    //           return true;
    //         }
    //       }
    //     }
    //   }
    //
    //   return false;
    // },

    recursion(arr, val) {
      if (this.industryName) {
        //结束递归
        return;
      }
      arr.find((item) => {
        if(this.industryName) {
          //结束arr遍历
          return true
        }
        if (item.title == val) {
          this.industryName = item.title;
          return true;
        }
        if (item.SUB_LIST && item.SUB_LIST.length > 0) {
          this.getIndustryName(item.SUB_LIST, val);
        }
      });
    },
    getUser(){
      getUserInfo({}).then(res=> {
        // console.log(res)
        localStorage.setItem('userInfo', JSON.stringify(res.result))
          this.userInfo = res.result
        if(res.result.realName === '系统管理员'){
        this.showUser = true
      }
        if(res.result.posName === '项目副经理' || res.result.posName === '项目经理'|| res.result.realName === '系统管理员'){
          localStorage.setItem('showRole',true)
          this.showRole = true
        }else{
          localStorage.setItem('showRole',false)
        }
        if(res.result.posName === '项目经理'|| res.result.realName === '系统管理员'|| res.result.posName === '项目书记'){
          localStorage.setItem('showRemark',true)
        }else{
          localStorage.setItem('showRemark',false)
        }
        if(res.result.posName === '项目书记'|| res.result.realName === '系统管理员'){
          localStorage.setItem('showOrg',true)
        }else{
          localStorage.setItem('showOrg',false)
        }
      })
    },
    loginOut() {
      this.$store.commit("clearUserInfo", "");
        // localStorage.removeItem('menu')
        this.$router.push({
        path: `/login`,
      });
    },
    collapsedSider() {
      this.$refs.side1.toggleCollapse();
    },
    linkto(a) {
      // // console.log(a);
      this.title2 = "";
      this.index = a == "/" ? "/" : a.replace("/", "");
      this.$router.push({
        path: `${a}`,
      });
      // // console.log(this.index);
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.title = val.meta.title;
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less">
  .ivu-page-item{
    background: unset !important;
  }
  .ivu-page-next, .ivu-page-prev{
    background: unset !important;
  }
.con {
  height: 100%;
}

.mainBox {
  // height: 100%;
}

.zwsj {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.content {
  height: calc(100vh - 104px);
  overflow-y: auto;
}

.body {
  background:url("../assets/body.jpeg") no-repeat;
    background-size: 100% 100%;
  /*color:rgba(24, 118, 187, 1);*/
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;

  // background: #40495b;
  img {
    width: auto;
    height: 30px;
  }
}

.nav li {
  cursor: pointer;
}

.layout {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  padding: 20px 40px 20px 40px;
  box-sizing: border-box;
  // height: 100vh;
  height: calc(100vh - 78px);
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.dev-run-preview .dev-run-preview-edit {
  display: none;
}

.ivu-menu-light li {
  /*margin-bottom: 1px;*/
}
  .ivu-menu-light li:hover{
      background: #fff !important;
      /*margin-bottom: 1px;*/
  }

.ivu-menu-light.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title {
  background: #fff !important;
    font-weight: bold;
    color: rgba(1, 12, 25, 1) !important;
}
  .ivu-menu-opened{
      border-left: 2px solid #1876BB;
  }

.ivu-menu .ivu-menu-item {
    background: #fff !important;
    color: #010c19 !important;
  /*background: hsl(192, 96%, 11%) !important;*/
  // padding-left: 55px !important;
  display: flex;
  align-items: center;
}

.ivu-menu .ivu-menu-item img {
  width: 18px;
  margin-right: 10px;
  height: auto;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover {
    background: #fff !important;
    font-weight: bold !important;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu-title:hover {
    background: #fff !important;
    font-weight: bold;
    color: rgba(1, 12, 25, 1) !important;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover {
    background: #fff !important;
    font-weight: bold;
    color: rgba(1, 12, 25, 1) !important;
}

.ivu-menu-submenu-title {
  img {
    width: 20px;
    display: inline-block;
    margin-right: 10px;
    height: auto;
  }

  display: flex;
  align-items: center;
}
.nav1 {
  background: rgba(24, 118, 187, 1);
    border-radius: 10px 10px 0px 0px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  /*box-shadow: 0px 0px 3px 2px #082b2d;*/
  position: absolute;
  width: 100%;
  z-index: 999;
}

.ivu-layout {
    width: 100%;
  margin-left: 0px !important;
  height: calc(100vh - 178px);
    background: unset !important;
}

.content {
  background:#fff;
    border-radius:10px;
  /*box-shadow: inset 0px 0px 6px 1px #1876BBb4;*/
}

.topImg {
  display: flex;
  justify-content: center;
  height: 78px;
  overflow: hidden;

  img {
    height: 78px;
  }

  .top-center {
    margin-top: -1px;
    width: 548px;
    position: relative;
  }

  .top-right,
  .top-left {
      display: block;
    width: 333px;
      height: 88px;

  }

  .imgLr {
    width: calc(100% - 960px);
    background: url(../assets/bg-top.png) repeat-x;
  }

  .logo {
    width: 265px;
    height: 44px;
    position: absolute;
    top: 30%;
    left: 50%;
    margin-left: -132px;
    margin-top: -22px;
  }
}

.navBox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .ivu-breadcrumb {
      color: #505050;
  }

  .ivu-breadcrumb>span:last-child {
      color:rgba(24, 118, 187, 1);
  }
    .ivu-breadcrumb-item-separator{
        color:rgba(80, 80, 80, 1) ;
    }
  .headers {
      color: #333333;
    display: flex;
    align-items: center;
    position: absolute;
    left: 40px;
  }

  .nav {
    width: 100%;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      // position: absolute;
      width: 100%;

      // top: 16px;
      li {
        width: 142px;
        height: 46px;
        background: url("../assets/btn.png") no-repeat;
        text-align: center;
        line-height: 40px;
        color: #1876BB;
        list-style: none;
        margin: 0 20px;
      }

      .active {
        color: #ffffff;
        background: url("../assets/nav-bg-s.png") no-repeat;
      }
    }
  }
}
  .ivu-menu-light.ivu-menu-vertical .ivu-menu-item, .ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu-title{
      color:rgba(1, 12, 25, 1) !important;
  }
.nav4 {
  width: 170px;
  background: #C2DAFD;
    border-radius: 10px 0 0 0 ;
  height: 100%;

  h1 {
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #1876BB;
    border-bottom-right-radius: 30px;
    text-align: center;
    line-height: 56px;
  }

  .ivu-menu .ivu-menu-item {
    background: none !important;
    font-size: 16px;
    text-align: center;
    height: 46px;
    line-height: 19px;
  }
    .ivu-menu .ivu-menu-item:hover {
        background: #1876BB !important;
        font-weight: bold;
        color:#fff !important;
        border-radius: 10px;
    }

  .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),
  .ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu) {
      background: #1876BB !important;
      font-weight: bold;
      color:#fff !important;
    border-radius: 10px;
  }

  .ivu-menu-light {
    padding: 1px 7px;
  }
}

.nav5 {
  .ivu-menu .ivu-menu-item {
    background: none !important;
  }

  .ivu-menu-light {
    background: none !important;
  }

  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item,
  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
    color: #fff;
  }

  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
      background: #fff !important;
      font-weight: bold !important;
    /*border-bottom: 1px solid #1876BB;*/
    // padding: 0;
  }

  .ivu-menu-horizontal.ivu-menu-light:after {
    background: none;
  }
}

// .ivu-menu-vertical .ivu-menu-submenu-title{
//   padding: 14px 16px;
// }
.nav4 .ivu-menu-vertical .ivu-menu-item {
  padding: 14px 7px !important;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),
.ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu) {
    background: #fff !important;
    font-weight: bold;
    color: rgba(1, 12, 25, 1) !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1876BB;
  opacity: 0.5;
}

::-webkit-scrollbar-track {
  background: #e2e7f1;
  border-radius: 4px;
  width: 6px;
}

.topImg {
  .top-r-icon {
    position: absolute;
    right: 40px;
    top: 10px;

    img {
      cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    .num {
      position: absolute;
      top: 0;
      right: 0;
      /*width: 16px;*/
      padding: 1px;
      /*height: 16px;*/
      background: #ff0000;
      font-size: 10px;
      font-weight: 500;
      color: #fff;
      border-radius: 100px;
      text-align: center;
      line-height: 16px;
    }
  }
}

.msgWrap {
  .btn {
    width: 202px;
    height: 36px;
    background: #1876BB;
    border-radius: 4px 4px 4px 4px;
  }

  width: 340px;
  height: calc(100% - 450px);
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  top: 52px;
  right: 10px;
  z-index: 100;
  padding: 19px 19px 40px 19px;

  .msg-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .msg-title {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #1876BB;

      &::after {
        content: "";
        width: 66px;
        height: 3px;
        position: absolute;
        bottom: -13px;
        left: 0;
        background-color: #1876BB;
      }
    }

    .msg-close {
      cursor: pointer;
      color: #1876BB;
    }
  }

  .msg-cont {
    position: relative;
    margin-top: 24px;
    height: calc(100% - 50px);
    overflow-y: scroll;
    overflow-x: hidden;

    .msgList {
      display: flex;
      align-items: center;
      border-bottom: 1px dotted rgba(220, 222, 226, 1);
      padding: 12px 0 18px 0;

      .msgListIcon img {
        margin-right: 10px;
        width: 36px;
        height: 36px;
      }

      .msgListTitle {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        color: #1876BB;
      }

      .msgListCont {
        margin-top: 4px;
        font-size: 12px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        color: #505050;
      }
    }
  }
}

.ivu-menu-light {
  background: none !important;
  /*padding: 1px 5px !important;*/
}

.ivu-layout-sider {
  max-width: 340px !important;
  width: 340px !important;
  left: 40px !important;
  height: calc(100vh - 178px);
    border-radius: 10px 10px 0 0;
  background: #ffffff !important;
  /*box-shadow: inset 0px 0px 6px 1px #1876BBb4 !important;*/
}

.charts {
  width: 100%;
  height: 327px;
  background: linear-gradient( 180deg, rgba(255,255,255,0.9) 0%, rgba(254,254,254,0) 100%);
  border-radius: 8px 8px 8px 8px;
  .style {

  }

  #chart1 {
    width: 100%;
    height: calc(50vh - 150px);
  }

  #chart2 {
    width: 100%;
    height: calc(50vh - 150px);
  }

  #chart3 {
    width: 100%;
    height: calc(50vh - 150px);
  }

  #chart4 {
    width: 100%;
    height: calc(50vh - 150px);
  }
}

.body2 {
  background: rgba(0, 28, 39, 0.2);

  .content {
    background: rgba(0, 28, 39, 0.5);
  }

  .ivu-table {
    background: rgba(14, 43, 53, 0.5);
  }

  .nav4 {
      border-radius: 10px 0 0 0 ;
      background: #C2DAFD;
  }
}

.layout2 {
  height: calc(100vh - 0px);

  .ivu-layout-sider {
    display: none;
  }

  .ivu-layout {
    margin-left: 0 !important;
    height: calc(100vh - 50px) !important;
  }

  .chartc {
    width: 100%;
    height: calc(50vh - 110px) !important;
  }

  .table1,
  .table2 {
    height: calc(50vh - 59px) !important;
  }
}

.layout3 {
  height: calc(100vh - 0px);


  .nav4 h1{
    background: rgba(24, 118, 187, 1);
  }
  .ivu-layout-sider {
    height: calc(100vh - 50px);
  }

  .ivu-layout {
    margin-left: 370 !important;
    height: calc(100vh - 50px) !important;
  }

  .chartc {
    width: 100%;
    height: calc(50vh - 50px) !important;
  }

  .table1,
  .table2 {
    height: calc(50vh - 59px) !important;
  }

  .ivu-menu-light.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title {
      background: #fff !important;
      font-weight: bold;
      color: rgba(1, 12, 25, 1) !important;
  }

  .ivu-menu-light li {
    /*background: rgba(2, 58, 68, .5);*/
  }

  .ivu-menu-light.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover {
     background:#fff !important;
  }

   .ivu-menu-opened {
       background: unset !important;
      border-left: 2px solid rgba(24, 118, 187, 1) !important;
  }

  .ivu-menu .ivu-menu-item {
    background: rgba(1, 44, 55, .3) !important;
  }
}
</style>
